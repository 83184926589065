import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src2997050974/src/kinetic-ui/docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "getcolor"
    }}>{`getColor`}</h1>
    <p>{`Certain objects can have a custom color defined for when they are displayed in certain places
such as cards. This value is defined on an attribute on the object. If the object does not have
a custom color and it is a model object with a slug (such as a kapp or form) a color will be
procedurally generated from that slug. You can also override the default color value as a
function parameter.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`import { Utils } from '@kineticdata/bundle-common';

// Returns a color typically a string in the format of 'rgb(126, 128, 131)'
Utils.getColor(team, 'rgb(255, 255, 255)');
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      